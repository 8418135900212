import React, { useEffect, useState, createContext } from "react";
import navigationData from '../../static/nav.json'

export const AppContext = createContext()

export const VERSIONS = navigationData.versions.map(x => ({ name: x.version, spec: x.spec }))


export const AppProvider = ({ children }) => {

    const [version, setVersion] = useState()

    useEffect(() => {
        const saved = JSON.parse(localStorage.getItem('kyckr-api-version')) || navigationData.versions[0]
        setVersion(saved)
    }, []);

    useEffect(() => {
        if (version != null) {
            localStorage.setItem('kyckr-api-version', JSON.stringify(version));
        }
    }, [version])

    const handleVersionChange = (event) => {
        const getVersion = navigationData.versions.filter(x => x.version === event.target.value)
        setVersion(getVersion[0]);
    };

    if (version) {
        return (
            <AppContext.Provider value={{ version, handleVersionChange }}>
                {children}
            </AppContext.Provider>
        )
    } else {
        return null
    }

}